@import '../../../styles/bootstrap/mixins';

.root {
    background-color: lighten($color: $success, $amount: 0.5);
    padding-top: 2rem;
    padding-bottom: 2rem;

    .title {
        color: #ffffff;
        font-size: 2.5rem;
        font-weight: 300;
        margin-bottom: 2rem;
        text-align: right;
    }

    .text {
        color: #ffffff;
        font-size: 1.25rem;
        font-weight: 500;
        text-align: right;
    }

    .success {
        align-items: center;
        background-color: white;
        color: $success;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        pointer-events: none;
        transition: opacity 200ms linear;
        z-index: 10;

        .icon {
            font-size: 4rem;
        }

        .text {
            font-size: 1.25rem;
            font-weight: 500;
        }

        &.visible {
            opacity: 1;
        }
        
    }

    .loader {
        opacity: 0;
        transition: opacity 200ms linear;
        
        &.loading {
            opacity: 1;
        }
    }

    .submit {
        align-items: center;
        display: flex;
        justify-content: flex-end;

        button {
            margin-left: 1rem;
        }
    }
}