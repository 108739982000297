@import '../../../styles/bootstrap/mixins';

.footer {
    background-color: #343A40;

    @include media-breakpoint-down(md) {
        padding-top: 1rem;
        padding-bottom: 1rem;

        div {
            text-align: center;
        }
    }

    @include media-breakpoint-only(lg) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    @include media-breakpoint-only(xl) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .logo {
        @include media-breakpoint-down(sm) {
            display: block;
            margin: 0 auto;
            max-width: 50vw;
        }
        @include media-breakpoint-up(md) {
            margin: auto;
            width: 100%;
        }
    }


    .networks {
        margin-left: -1rem;
        margin-right: -1rem;
    }

    .social {
        color: darken($color: #ffffff, $amount: 0.1);
        font-size: 150%;

        &:hover {
            color: #ffffff;
        }
    }

    .contact {
        color: darken($color: #ffffff, $amount: 0.1);
        font-size: 125%;

        span {
            margin-left: 0.75rem;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .col {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .selo {
        @include media-breakpoint-down(sm) {
            margin: 1rem auto;
            max-width: 10rem;
        }
        @include media-breakpoint-up(md) {    
            margin: auto;
            width: 80%;
        }
    }
}

.link {
    color: darken($color: #ffffff, $amount: 0.1);
    display: block;
    padding-bottom: 0.375rem;
    padding-top: 0.375rem;

    &:hover {
        color: #ffffff;
        text-decoration: none;
    }
}

.copyright {
    color: rgba(white, 0.8);
    margin-bottom: 0;
    margin-top: 2rem;
    text-align: center;
}