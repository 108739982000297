.container {
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;

    &.loaded {
        .frame {
            opacity: 1;
        }

        .preview {
            opacity: 0;
            pointer-events: none;
        }
    }

    .preview {
        display: block;
        object-fit: cover;
        height: 100%;
        width: 100%;
        transition: filter opacity 200ms ease-in-out;

        &:hover {
            cursor: pointer;
            filter: brightness(1.05);

            .icon {
                color: rgb(252, 13, 27);
            }
        }

        .icon {
            color: rgba(black, 0.7);
            font-size: 6rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: color 100ms ease-in-out;
        }
    }

    .frame {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        opacity: 0;
        transition: opacity 200ms ease-in-out;
    }
}