.col {
    height: 0;
    padding-bottom: 56.75%;
    position: relative;
}

.container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    iframe {
        border: 0;
        height: 100%;
        width: 100%;
    }
}