@import '../../styles/bootstrap/mixins';

.banner {
    background-image: url('/img/home-bg-sm.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;

    @include media-breakpoint-up(lg) {
        background-image: url('/img/home-bg-md.jpg');
    }

    @include media-breakpoint-up(xl) {
        background-image: url('/img/home-bg-lg.jpg');
    }


    .system {
        @include media-breakpoint-down(sm) {
            display: block;
            margin: 0 auto;
            height: auto;
            width: 30vw;
        }

        @include media-breakpoint-up(md) {
            height: auto;
            width: 100%;
        }
    }

    @include media-breakpoint-down(sm) {
        .logo {
            display: block;
            margin: 0 auto;
            max-width: 30vw;
        }
    }

    .title {
        align-items: center;
        background-color: rgba(black, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        height: 100%;
        width: 100%;

        .company {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        img {
            // filter: drop-shadow(0px 0px 4px rgba(white, 0.5));
        }

        h1 {
            color: #ffffff;
            font-size: 2.25rem;
            font-weight: 500;
            text-align: center;
            text-shadow: 0 0 0.25rem rgba(black, 0.4);
        }

        .button {
            align-items: center;
            background: none;
            border: none;
            color: #ffffff;
            display: flex;
            flex-direction: column;
            font-size: 1.75rem;
            font-weight: 500;
            position: absolute;
            bottom: 0;
            left: 50%;
            text-align: center;
            transform: translateX(-50%);

            &:focus {
                outline: none;
            }

            .more {
                font-size: 0.75em;
                margin-bottom: 0.5em;
            }

            .arrow {
                align-items: center;
                border: 2px solid #ffffff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                height: 1.5em;
                width: 1.5em;
            }
        }

        @include media-breakpoint-down(md) {
            h1 {
                font-size: 2rem;
                text-align: center;
            }
        }

        @include media-breakpoint-only(lg) {
            img {
                margin-bottom: 2rem;
                max-width: 30vw;
            }
            .button {
                margin-bottom: 2rem;
            }
        }

        @include media-breakpoint-up(xl) {
            img {
                margin-bottom: 2rem;
                max-width: 30vw;
            }
            .button {
                margin-bottom: 2rem;
            }
        }

        
    }
}

.content {
    padding-top: 2rem;
    padding-bottom: 2rem;

    .title {
        font-size: 1.25rem;
        font-weight: 500;
        margin-top: 0.75rem;
        margin-bottom: 1rem;
    }

    .description {
        font-size: 0.75rem;
    }

    .sensor {
        height: auto;
        width: 100%;
    }

    .image {
        margin-bottom: 0.5rem;
    }

    .image > div {
        max-height: 0;
        padding-bottom: 100%;
        position: relative;
        div {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            img {
                border-radius: 50%;
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }

    .image:nth-child(4) {
        img {
            object-fit: contain !important;
        }
    }

    .card {
        padding-bottom: 1.5rem;

        img {
            display: block;
            margin: 1rem auto;
        }

        .title {
            display: block;
            font-size: 1.5em;
            font-weight: 500;
            letter-spacing: 0.125rem;
            margin-bottom: 1rem;
            text-align: center;
        }

        .description {
            display: block;
            font-size: 0.9375em;
            text-align: justify;
        }
    }
}

.video {
    background-color: #333333;
}

h3.title {
    color: $primary;
}

.news {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
