@import '../../../styles/bootstrap/mixins';

.root {
    height: 0;
    overflow: visible;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.nav {
    // background-image: linear-gradient(to bottom);
    background-image: linear-gradient(rgba(#343A40, 0.8), rgba(#343A40, 0));
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    transition: background-color 200ms;

    @include media-breakpoint-down(sm) {
        a {
            border-radius: 0.2rem;
            padding: 0.125rem 0.5rem;
            font-size: 0.875rem;
            line-height: 1.5;

            svg {
                width: 1.5em;
            }
        }
    }
}

.background {
    background-color: #343A40;
}